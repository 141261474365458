import ImageSlider from '../components/ImageSlider';
import About from '../components/About';
import Services from '../components/Services';
import Contact from '../components/Contact';
import Location from '../components/Location';

function Main() {
  return (
    <>
      <main>
      <About />
      <ImageSlider />
        <Services />
        <Contact />
        <Location />
      </main>
    </>
  );
}
export default Main;
