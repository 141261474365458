import '../index.css';
function Header() {
  return (
    <header>
      
      <h1></h1>
      
      <video src="http://localhost:3000/company-website-reactjs/DronaII.mp4" loop autoPlay muted></video>
      {/* <div>
        <img src="http://localhost:3000/company-website-reactjs/DronMolds.png" style={{opacity:"0.3",borderRadius: "20px"}} alt="Transparent" />
      </div> */}

      <div className="headerbg"></div>
    </header>
  );
}
export default Header;

