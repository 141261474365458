import React, { useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    // Use emailjs to send the form data
    emailjs.sendForm('service_mjuqipm', 'template_moxqrak', e.target, 'ikVdhkR6vkDMnOB0H')
      .then((result) => {
        
        alert('Thanks for showing interest in us. We will contact you shortly !');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send message, please try again.');
      });

    // Optionally clear the form after submission
    setFormData({
      name: '',
      email: '',
      subject: '',
      message: ''
    });
  };

  return (
    <form onSubmit={sendEmail}>
      <div className="container contact">
        <h2 className="main-title text-center" style={{color:"#31bfba"}}>CONTACT</h2>
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 mb-1">
              <input
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Name"
                className="contact-input"
              />
            </div>

            <div className="col-md-4 mb-1">
              <input
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="contact-input"
              />
            </div>
            <div className="col-md-4 mb-1">
              <input
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Subject"
                className="contact-input"
              />
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12">
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Message"
            className="contact-textarea"
          />
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <input className="form-btn" type="submit" value="Send Message" />
          </div>
        </div>
      </div>
    </form>
  );
}

export default Contact;
