function Card(props) {
  // Split the text into an array of items to create bullet points
  const textItems = props.text.split('\n');
  const iconImage = "http://localhost:3000/company-website-reactjs/img1/Picture.png";

  return (
    <div className="card">
      <br />
      <div className="text-center">
        <img alt="card-img" src={"http://localhost:3000/company-website-reactjs/img1/"+props.img} className="text-center img-fluid" />
      </div>
      <div className="text-center">
        <h3 className="card-title">{props.title}</h3>
      </div>
      <div className="p-3">
        <ul className="card-text" style={{ paddingLeft: '20px' }}>
          {textItems.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Card;
