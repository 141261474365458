import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom Previous Arrow
const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "blue", // Change arrow color here
        borderRadius: "50%", // Optional: makes the arrow round
        padding: "10px",
        zIndex: 10, // Ensure arrow appears above the images
        left: "-25px", // Adjust the position of the arrow to be outside the slider
      }}
      onClick={onClick}
    />
  );
};

// Custom Next Arrow
const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "red", // Change arrow color here
        borderRadius: "50%", // Optional: makes the arrow round
        padding: "10px",
        zIndex: 10, // Ensure arrow appears above the images
        right: "-25px", // Adjust the position of the arrow to be outside the slider
      }}
      onClick={onClick}
    />
  );
};

const ImageSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    nextArrow: <NextArrow />, // Use custom next arrow
    prevArrow: <PrevArrow />, // Use custom previous arrow
    responsive: [
      {
        breakpoint: 768, // For tablets and mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          speed: 1000,
          centerMode: false, // Disable centering for mobile
        },
      },
    ],
  };

  const images = [
    "http://localhost:3000/company-website-reactjs/img1/1.png",
    "http://localhost:3000/company-website-reactjs/img1/2.png",
    "http://localhost:3000/company-website-reactjs/img1/3.png",
    "http://localhost:3000/company-website-reactjs/img1/4.png",
    "http://localhost:3000/company-website-reactjs/img1/5.png",
    "http://localhost:3000/company-website-reactjs/img1/6.png",
    "http://localhost:3000/company-website-reactjs/img1/7.png",
    "http://localhost:3000/company-website-reactjs/img1/8.png",
    "http://localhost:3000/company-website-reactjs/img1/9.png",
    "http://localhost:3000/company-website-reactjs/img1/10.png",
    "http://localhost:3000/company-website-reactjs/img1/11.png",
  ];

  return (
    <div
      style={{
        width: "90%",
        margin: "0 auto",
        perspective: "1000px",
        position: "relative", // Ensure arrows are positioned relative to the slider
      }}
    >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              padding: "0 10px",
              transform: `scale(${index === 1 ? 1.5 : 0.5}) translateZ(${index === 1 ? 50 : -20}px)`, // Scale up the center image and scale down others
              transition: "transform 0.5s ease",
              opacity: index === 1 ? 1 : 0.5, // Center image is fully opaque, others are more faded
            }}
          >
            <div
              style={{
                position: "relative",
                overflow: "hidden",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                transition: "transform 0.3s ease",
                transformStyle: "preserve-3d",
              }}
            >
              <img
                src={image}
                alt={`Slide ${index + 1}`}
                style={{
                  width: "100%",
                  height: "auto", // Changed to auto to maintain aspect ratio
                  objectFit: "cover",
                  transition: "transform 0.5s ease",
                  backfaceVisibility: "hidden",
                }}
              />
              {/* Overlay for hover effect */}
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "rgba(0, 0, 0, 0.4)",
                  opacity: 0,
                  transition: "opacity 0.5s ease",
                }}
                className="overlay"
              ></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
