function About() {
  return (
    <>
      <div style={{marginTop:'15rem',width:'100%',height:'10px'}} className="about-scroll"></div>

      <div className="container about">
        <div className="row">
          <div style={{paddingTop:'100px'}} className="col-md-6 text-center">
            <img alt="about" src="http://localhost:3000/company-website-reactjs/Introdution.png" className="img-fluid" />
          </div>
          <div className="col-md-6">
            <h2 className="main-title about-h2" style={{color:"#31bfba"}}>ABOUT</h2>
            <p className="main-p">
            Drona Molds is a high precision tool room committed by 
supreme quality, cost effectiveness, constant delivery and 
continual services.
 We Expertise in New product development; Design, 
Manufacturing and maintenance of all type of Injection Mold,
Press Tools, Blow Mold, Pet Mold, Thermoforming, Fixtures
and Hot Runners. 
We are driven by the experts who have several 
years of experience in precision tool rooms in India and abroad, 
with vast knowledge of markets, advanced technologies and various customer requirements.

            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
