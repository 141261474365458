import Card from "./Card";
import React from 'react';

function Services() {
  return (
    <div className="container services">
      <h2 className="main-title text-center" style={{color:"#31bfba"}}>SERVICES</h2>
      <div className="card-cover">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-3 mb-2">
              <Card title="Business Overview" img="BusinessOverview.png" text={`We specialize and focus on a range of industries like Medical, Packaging, Automotive, Electric, and other engineering fields.\n We have a proven background with specialized manufacturing skills.\n We Expertise in New product development; Design, Manufacturing and maintenance of all type of Injection Mold,Press Tools, Blow Mold, Pet Mold, Thermoforming, Fixtures and Hot Runners.\n We are facilitated with skilled human capital, state-of-the-art equipment.\n We located at Thrissur, in the heart of Kerala, S outh  India.`} />
            </div>
            <div className="col-md-3 mb-2">
              <Card title="Why Drona Molds"  img='WhyDronaMolds.png' text={`The major strength of Drona Molds is its incredible team with a strong familiarity in the ultra-precision engineering industry.\n The expertise technicians & professionals are joining together with their experience and exposure in a multi-national work culture.\n The team is well experienced in the standards and norms of all major OEMs.\n The team is skilled for new product development, project planning, designing, mold manufacturing, and customer interactions.\n Drona Mold is planted in a logistics-friendly location with easy access to sea ports and airports.`} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 mb-2">
              <Card title="Our Vision" img="OurVision.png" text={`To be a leading provider of innovative and high-quality tooling solutions; recognized for our commitment to excellence, customer satisfaction, and innovation while fostering a culture of continuous improvement, professionalism.`} />
            </div>
            <div className="col-md-4 mb-2">
              <Card title="Our Mission" img="OurMission.png" text={`To provide efficient and reliable tooling solutions that meet the needs of our customers, while ensuring safety and sustainability, through continuous improvement and a commitment to excellence`} />
            </div>
            <div className="col-md-4 mb-2">
              <Card title="Core Values" img="CoreValues.png" text={`To be a leading provider of innovative and high-quality tooling solutions; recognized for our commitment to excellence, customer satisfaction, and innovation while fostering a culture of continuous improvement, professionalism.`} />
            </div>
            
         </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
