import { Link, animateScroll as scroll } from 'react-scroll'

function Footer() {


  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3">
                <p className="logo-footer">
                <img style={{width:'100%'}} src='http://localhost:3000/company-website-reactjs/img1/SAVE_20240310_173155-removebg-preview.png' />
                

                </p>
                {/* <p className="footer-text">
                  Master Of Accurancy
        
                </p> */}
              </div>
              <div className="col-md-3">
                <p className="footer-title">Important Link</p>
                <ul>
                  <li>
                  <Link   
                         spy={true}
                         smooth={true}
                         duration={1000}
                         to="headerbg"
                  > Home </Link>
                  </li>
                  <li>
                  <Link to="services" spy={true} smooth={true} duration={1000} > Services </Link>
                  </li>
                  <li>
                  <Link to="about-scroll" spy={true} smooth={true} duration={1000}>About Us  </Link>
                  </li>
                  <li>
                  <Link to="contact" spy={true} smooth={true} duration={1000}> Contact  </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                  <a target="_blank" rel="noreferrer" href="https://accounts.google.com/InteractiveLogin/signinchooser" > info@dronamolds.com</a>
                    {/* <Link to="#" >info@dronamolds.com</Link> */}
                  </li>
                  <li>
                    <Link to="#" >St.Marys ITI Campus Kachery,East fort, Thrissur,Kerala,India Pin : 680005</Link>
                  </li>
                  <li>
                    <Link to="#" >8606606400</Link>,,<Link to="#" >8606606500</Link>,,<Link to="#" >8606606505</Link><br/>
                  </li>
                  <li>
                    <Link to="#" >UAE: +971 569643320</Link><br/>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.facebook.com/people/Drona-Molds/100089774101496/" > 
                    <img src="http://localhost:3000/company-website-reactjs/img1/facebook.png" alt="Facebook" style={{ width: '20px', marginRight: '8px' }} />
                      FaceBook
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/dronamolds/"> 
                    <img src="http://localhost:3000/company-website-reactjs/img1/instagram.png" alt="Facebook" style={{ width: '20px', marginRight: '8px' }} />
                    InstaGram
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/DronaMolds" >
                    <img src="http://localhost:3000/company-website-reactjs/img1/twitter.png" alt="Facebook" style={{ width: '20px', marginRight: '8px' }} />
                    X (Twitter)</a>
                  </li>
                  <li>
                    <a target="_blank" rel="noreferrer" href="https://twitter.com/DronaMolds">
                    <img src="http://localhost:3000/company-website-reactjs/img1/linkedin.png" alt="Facebook" style={{ width: '20px', marginRight: '8px' }} />
                    Linkedin</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button onClick={() => scroll.scrollToTop(2500)} src="" className="gotop"><i className="fas fa-level-up-alt"></i></button>

    </footer>
  );
}
export default Footer;
