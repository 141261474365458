function Location() {
  return (
    <div className="container Location">
      <h2 className="main-title text-center" style={{color:"#31bfba"}}>Location</h2>
      <div className="col-md-12">
        <img src="http://localhost:3000/company-website-reactjs/img1/Location.png"></img>
        <img src="http://localhost:3000/company-website-reactjs/img1/QRCode.png"></img>
        <a href="https://maps.app.goo.gl/jcpuwTPeiDcWA5yG7" target="_blank" rel="noopener noreferrer">Open Google Maps</a>
      </div>
    </div>
  );
}
export default Location;
